


























































import { vxm } from "@/store";
import {Component, Vue, Prop, Watch} from "vue-property-decorator";
import { ProfileDTO } from "@shared_vue/openapi/myvaultapi";
import {CaseApiFactory, CaseDTO, Configuration} from "@shared_vue/openapi/caseapi";
import { OptionDTO } from "@shared_vue/openapi/myvaultapi";
import { CTextarea } from "@coreui/vue";
import TbButton from "@/views/TbButton.vue";

@Component({
  components: {
    TbButton
  },
})
export default class Step2 extends Vue { //steps are so similar, can we not merge them into a generic step
  @Prop() readonly person: ProfileDTO | undefined;
  @Prop() readonly realCase!: CaseDTO;
  @Prop() readonly gotoStep!: Function;
  private caseApi = CaseApiFactory(
      <Configuration>{ basePath: process.env.VUE_APP_CASE_API_URL },
      process.env.VUE_APP_CASE_API_URL
  );
  private ui = vxm.ui;
  private caseStore = vxm.case;
  private publicPath = process.env.VUE_APP_STATIC_DIR;
  private selectedCategory: OptionDTO | undefined;

  @Watch('realCase')
  onCase(val: CaseDTO, oldval: CaseDTO) {
    console.log('val');
    const incidentDataFields = ['incidentDescription', 'incidentAddress', 'suspectDetails', 'contactDetails'];
    for (const field of incidentDataFields) {
      //@ts-ignore
      this.incidentData[field] = val?.caseData?.[field] ?? this.incidentData[field];
    }
  }

  get caseAddressString() {
    return this.realCase?.address??"";
  }
  
  private incidentData: any =  {
    //@ts-ignore
      incidentDescription: '',
      incidentAddress: '',
      suspectDetails: '',
      contactDetails: ''
    };
  
  private async saveAndGo() { //add modal
    this.$emit('saveandnext',3, this.incidentData)
  }
}
