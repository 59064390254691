

























































































import { vxm } from "@/store";
import {Component, Vue, Prop, Watch} from "vue-property-decorator";
import {
  CaseApiFactory,
  CaseDTO,
  CaseStatus, CaseType,
  Configuration,
} from "@shared_vue/openapi/caseapi";
import Step1 from "@/views/pages/steps/step1.vue";
import { OptionDTO, ProfileDTO } from "@shared_vue/openapi/myvaultapi";
import Step2 from "@/views/pages/steps/step2.vue";
import Step3 from "@/views/pages/steps/step3.vue";
import Step4 from "@/views/pages/steps/step4.vue";
import Step5 from "@/views/pages/steps/step5.vue";
import Step6 from "@/views/pages/steps/step6.vue";
import Step7 from "@/views/pages/steps/step7.vue";
import WaitModal from "@/views/widgets/modals/WaitModal.vue";
import ErrorModal from "@/views/widgets/modals/ErrorModal.vue";

@Component({
  components: {ErrorModal, WaitModal, Step1, Step2, Step3, Step4, Step5, Step6, Step7 },
})
export default class LiveCase extends Vue {
  private ui = vxm.ui;
  private showWait: boolean = false;
  private caseStore = vxm.case;
  private auth = vxm.auth;
  private waitABit = false;
  private showError = false;
  private titleString = 'Loading...';
  private publicPath = process.env.VUE_APP_STATIC_DIR;
  private caseApi = CaseApiFactory(
    <Configuration>{ basePath: process.env.VUE_APP_CASE_API_URL },
    process.env.VUE_APP_CASE_API_URL
  );
  @Prop(String) readonly caseid: string|undefined;
  private steps: number = 7;
  private currentStep: number = 1;
  
  get currentCase() {
    return this.caseStore.openCase;
  }
  
  get modalTitle(){
    return this.titleString;
  }
  
  private async refresh(caseData: CaseDTO){
    this.showWait=true;
    let caseObj = await this.caseApi.caseGetCaseById(parseInt(this.caseid!));
    this.caseStore.setCurrentCase(caseObj.data);
    this.showWait=false;
  }
  public async fetchData(){
    let result = await this.caseApi.caseGetCaseCategoryOptions();
    let caseObj = await this.caseApi.caseGetCaseById(parseInt(this.caseid!)); //use store or fetch fresh here?
    

    if (result.status==200){
      // let zeroth = <OptionDTO>{ id: "", text: "Choose" }
      this.caseCategory = result.data;
    }
    
    if (caseObj.status==200) {
      console.log('got case');
      console.log(caseObj.data);
      this.caseStore.setCurrentCase(caseObj.data);
    }
  }

  @Watch('auth.idToken')
  onIdToken(val: boolean, oldval: boolean) {
    console.log('onidtoken')
    if (this.waitABit){
      this.fetchData();
    }
  }
  
  mounted(){
    this.titleString="Loading...";
    if (!this.auth.idToken) {
      this.waitABit = true;
      return;
    }
    this.fetchData();
  }
  
  private caseCategory: CaseType[] = [];

  private person: ProfileDTO = <ProfileDTO>{};
  
  private async goToStep(which: number, incidentData: any) {
    console.log('gotostep')
    this.titleString="Saving...";
    this.showWait=true;
    if (which===2){
      this.currentStep=2;
      this.showWait=false;
      return;
    }
    try {
      let result = await this.caseApi.caseSaveCaseData(this.currentCase.id!, incidentData);
      if (result.status==200) {
        this.showWait = false;
        if (which !== 8) {
          this.currentStep = which;
        } else {
          //done
          this.$router.push('/dashboard/cases')
        }
      } else {
        throw new Error('bad response')
      }
    } catch (e){
      this.showWait=false;
      console.log(e)
      this.showError=true;
    }
  }
}
