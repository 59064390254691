


































import { vxm } from "@/store";
import {Component, Vue, Prop, Watch} from "vue-property-decorator";
import { ProfileDTO } from "@shared_vue/openapi/myvaultapi";
import {CaseApiFactory, CaseDTO, Configuration} from "@shared_vue/openapi/caseapi";
import { OptionDTO } from "@shared_vue/openapi/myvaultapi";
import { CTextarea } from "@coreui/vue";
import TbButton from "@/views/TbButton.vue";

@Component({
  components: {
    TbButton
  },
})
export default class Step3 extends Vue {
  @Prop() readonly person: ProfileDTO | undefined;
  @Prop() readonly realCase: CaseDTO | undefined;
  @Prop() readonly gotoStep!: Function;
  

  private caseApi = CaseApiFactory(
      <Configuration>{ basePath: process.env.VUE_APP_CASE_API_URL },
      process.env.VUE_APP_CASE_API_URL
  );

  @Watch('realCase')
  onCase(val: CaseDTO, oldval: CaseDTO) {
    console.log('val');
    const incidentDataFields = ['servicesDispatched'];
    for (const field of incidentDataFields) {
      //@ts-ignore
      this.incidentData[field] = val?.caseData?.[field] ?? this.incidentData[field];
    }
  }

  private incidentData: any =  {
    servicesDispatched: '',
  };

  private async saveAndGo() {

    this.$emit('saveandnext',4, this.incidentData)
  }
}
