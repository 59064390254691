








































































import { vxm } from "@/store";
import {Component, Vue, Prop, Watch} from "vue-property-decorator";
import { ProfileDTO } from "@shared_vue/openapi/myvaultapi";
import {CaseApiFactory, CaseDTO, CaseStatus, Configuration} from "@shared_vue/openapi/caseapi";
import { OptionDTO } from "@shared_vue/openapi/myvaultapi";
import TbButton from "@/views/TbButton.vue";
import WaitModal from "@/views/widgets/modals/WaitModal.vue";
import CalendarModal from "@/views/widgets/modals/CalendarModal.vue";


@Component({
  components: {
    TbButton,
    WaitModal,
    CalendarModal
  },
})
export default class Step7 extends Vue {
  @Prop() readonly person: ProfileDTO | undefined;
  @Prop() readonly realCase: CaseDTO | undefined;
  @Prop() readonly gotoStep!: Function;

  private ui = vxm.ui;
  private showWait = false;
  private caseStore = vxm.case;
  private publicPath = process.env.VUE_APP_STATIC_DIR;
  private selectedCategory: OptionDTO | undefined;
  private showCal = false;
  private modalTitle: string = "Please Wait"

  private caseApi = CaseApiFactory(
      <Configuration>{ basePath: process.env.VUE_APP_CASE_API_URL },
      process.env.VUE_APP_CASE_API_URL
  );

  private async handleDateSelected(arg: any){
    console.log('got date')
    console.log(arg);
    try {
      this.showWait = true;
      //change state to "ONHOLD" and set reminder time
      await this.caseApi.casePauseCase({
        caseId: this.realCase!.id!,
        until: arg
      })
    } catch (e) {

    } finally {
      this.showWait = false;
    }
  }

  private incidentData: any =  {
    finalUpdate: '',
    eventsTimeLine: '',
    advanceTrackingCaseStack: ''

  };

  get caseStateOptions(){
    return Object.values(CaseStatus).map(item => ({value:item.toString().toUpperCase(), label:item.toString().toUpperCase()}));
  }

  get selectedCaseState() : string {
    if (this.realCase!.id != undefined) {
      return this.realCase!.status?.toString()??''
    } else {
      return ''
    }
  }
  
  async saveCaseState(toWhat: string) {
    try {
      this.showWait = true;
      await this.caseApi.caseBulkUpdateCaseStates({
        caseIds: [this.realCase!.id!],
        status: toWhat
      })
    } finally {
      this.showWait = false;
    }
  }

  private reverseMap: Map<string,CaseStatus> = new Map(Object.values(CaseStatus).map(item => [item.toString().toUpperCase(), item]))
  
  set selectedCaseState(toWhat: string){
    //save straight away this time
    console.log('case state change:' + toWhat)
    let enumValue: CaseStatus|undefined = this.reverseMap.get(toWhat);
    this.realCase!.status = enumValue;
    
    if (toWhat==='ONHOLD'){
      this.showCal=true;
    } else {
      this.saveCaseState(toWhat);
    }
  }
  
  @Watch('realCase')
  onCase(val: CaseDTO, oldval: CaseDTO) {
    console.log('val');
    const incidentDataFields = ['finalUpdate', 'eventsTimeLine', 'advanceTrackingCaseStack'];
    for (const field of incidentDataFields) {
      //@ts-ignore
      this.incidentData[field] = val?.caseData?.[field] ?? this.incidentData[field];
    }
  }

  private async saveAndGo() {
    this.$emit('saveandnext',8, this.incidentData)
    // let result = await this.caseApi.caseSaveCaseData(this.realCase!.id!, this.incidentData);
    // let caseInstance: CaseDTO = {
    //   id: this.realCase!.id!,
    //   status: CaseStatus.Closed
    // };
    // this.caseApi.caseUpdateCase(caseInstance);
    // // navigate to case stack
    // this.$router.push('/dashboard/cases')
  }
}
